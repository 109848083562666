import React from 'react';
import { css } from '@emotion/react';

const About: React.FC = () => {
  return (
    <section>
      <h2>About</h2>
      <dl>
        <dt>Name</dt>
        <dd>y4shiro</dd>
      </dl>
    </section>
  );
};

export default About;
