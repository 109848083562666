const hex2rgba = (hex: string, opacity: number) => {
  if (hex.slice(0, 1) == '#') hex = hex.slice(1);
  if (hex.length == 3)
    hex =
      hex.slice(0, 1) +
      hex.slice(0, 1) +
      hex.slice(1, 2) +
      hex.slice(1, 2) +
      hex.slice(2, 3) +
      hex.slice(2, 3);

  const rgbaArray = [
    parseInt(hex.slice(0, 2), 16),
    parseInt(hex.slice(2, 4), 16),
    parseInt(hex.slice(4, 6), 16),
    opacity,
  ];

  return rgbaArray;
};

export default hex2rgba;
